import Flickity from 'flickity';

export default {
  init() {
    var figure = $(".home-video").hover(hoverVideo, hideVideo);

    function hoverVideo(e) {
        $('video', this).get(0).play();
    }

    function hideVideo(e) {
        $('video', this).get(0).pause();
    }

    this.bannerHomeVideos = document.querySelectorAll('.banner-home video');
  },
  finalize() {
      if (this.bannerHomeVideos) {
        for (var v = 0; v < this.bannerHomeVideos.length; v++) {
          this.bannerHomeVideos[v].pause();
        }
      }

    if (document.querySelector('.products-carousel') !== null) {
      //console.log('.products-carousel');
      var currentSlideIndexElement = document.querySelector('.products-carousel-index .current-slide')
      // require('flickity-imagesloaded');
      var productCarousel = new Flickity('.products-carousel', {
          cellAlign: 'left',
          adaptiveHeight: false,
          wrapAround: false,
          fullscreen: true,
          // autoPlay: 5000,
          // groupCells: true,
          pageDots: false,
          prevNextButtons: true,
          setGallerySize: false,
          // imagesLoaded: true
          // pauseAutoPlayOnHover: false,
          // selectedAttraction: 0.1,
          // friction: 1
          on: {
            // ready: function() {
            //   console.log('Flickity is ready');
            // },
            change: function( index ) {
              // console.log( 'Slide changed to ' + index );
              var currentSlide = index + 1;
              //console.log(currentSlide.length);
              if (currentSlide < 10) {
                currentSlide = "0" + currentSlide;
              }
              currentSlideIndexElement.textContent = currentSlide;
            }
          }
      });
    }
  }
}
