// components/category-select/index

function CategorySelect(postsQueryParam, current)
{
  console.log("CategorySelect");

    //this.postsQueryParam = postsQueryParam;

    this.select = document.querySelector(".category-select");

    if (this.select) {
        this.current = this.select.querySelector(".category-select-current");
        this.list = this.select.querySelector(".category-select-list");
        this.icon = this.select.querySelector(".category-select-icon");
        this.links = this.select.querySelectorAll(".category-select-item-link");
      } else {
        return false;
    }

    if (this.select.nodeType !== 1) {
        return false;
    }

    this.eventListeners();

    // if (current != "") {
    //     for (var k = 0; k < this.links.length; k++) {
    //         if (this.links[k].dataset.slug == current) {
    //             this.links[k].dispatchEvent(new MouseEvent("click"));
    //             break;
    //         }
    //     }
    // }
}

CategorySelect.prototype.eventListeners = function() {
    this.events = {
        body: this.bodyClick.bind(this)
    };

    // for (var i = 0; i < this.links.length; i++) {
    //     this.links[i].addEventListener("click", this.linkClick.bind(this), false);
    // }

    this.current.addEventListener("click", this.onClick.bind(this), false);

    this.icon.addEventListener("click", this.onClick.bind(this), false);

    this.select.addEventListener("click", function (e) {
        e.stopPropagation();
    });
};

CategorySelect.prototype.bodyClick = function () {
  console.log("bodyClick");
    this.select.classList.remove("is-open");
    // this.select.classList.add("is-closed");
    // jQuery(this.list).slideUp(150);
};

CategorySelect.prototype.onClick = function (e) {
    e.preventDefault();
    console.log("click");
    // var filterLabelLink = document.getElementById('filter-videos-link');
    //     filterLabelLink.textContent = this.textContent;
    this.select.classList.toggle("is-open");

    // this.select.classList.remove("is-closed");
    // this.select.classList.add("is-open");

    // jQuery(this.list).slideToggle(150);

    document.addEventListener("click", this.events.body);
};

// CategorySelect.prototype.linkClick = function (e) {
//     e.preventDefault();
//     console.log("linkClick");
//     jQuery('.category-select-current').text(e.target.text);
//     jQuery(e.target).parent().hide();
//     jQuery(e.target).parent().siblings().removeClass('d-none').show();

//     // window.postsQuery[this.postsQueryParam] = e.target.dataset.slug;
//     // window.postsQuery.page = 1;

//     this.select.classList.remove("is-open");
//     // this.select.classList.add("is-closed");

//     // jQuery(this.list).slideUp(150);

//     var ev = new CustomEvent("filter-posts");
//     document.dispatchEvent(ev);
// };

export default CategorySelect;
