import 'bootstrap/js/src/alert';
import 'bootstrap/js/src/button';
import 'bootstrap/js/src/util';
import bsCustomFileInput from 'bs-custom-file-input';
//import ajax from "@fdaciuk/ajax";

export default {
    init() {
      bsCustomFileInput.init();
    },
    finalize() {
    }
};
