//import jquery from "jquery";
import CategorySelect from "../components/category-select";
// import FilterPosts from "../components/filter-posts";
// import MorePosts from "../components/more-posts";

export default {
    init() {
      // console.log("products");

      // window.postsQuery = {
      //     //"product_category": [],
      //     "perpage": 3,
      //     "page": 2,
      // }

        // new FilterPosts("product");

        // new MorePosts("resources", true);

        // if (window.location.search.length > 0) {
        //     var doFilter = false;
        //     var url = require('url');
        //     var urlObj = url.parse(window.location.toString(), true);
        //     for (var i in urlObj.query) {
        //         switch(i) {
        //             case 'type':
        //                 window.postsQuery.resource_type.push(urlObj.query[i]);
        //                 window.postsQuery.page = 1;
        //                 doFilter = true;
        //                 var typeLinks = document.querySelectorAll("a.filter-link");
        //                 for (var t = 0; t < typeLinks.length; t++) {
        //                   if (typeLinks[t].dataset.filter == urlObj.query[i]) {
        //                     typeLinks[t].classList.add("active");
        //                     break;
        //                   }
        //                 }
        //                 break;
        //             case 'issue':
        //                 window.postsQuery.issue = urlObj.query[i];
        //                 window.postsQuery.page = 1;
        //                 doFilter = true;
        //                 break;
        //             case 'page':
        //                 window.postsQuery.page = parseInt(urlObj.query[i]);
        //                 doFilter = true;
        //                 break;
        //         }
        //     }

        //     if (doFilter === true) {
        //         document.dispatchEvent(new CustomEvent("filter-posts"));
        //     }
        // }

        new CategorySelect();
    },
    finalize() {

    }
};
