// import "jquery";
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/util';
import Flickity from 'flickity';

export default {
    init() {
      // console.log('single product');
      // $('#accordionExample').collapse();

      var prices = document.querySelectorAll('.product-price');
      var weightLinks = document.querySelectorAll('.product-weight-link');
      for (var w = 0; w < weightLinks.length; w++) {
        weightLinks[w].addEventListener('click', function(e) {
          e.preventDefault();

          if (e.target.parentNode.previousElementSibling !== null) {
            e.target.parentNode.previousElementSibling.classList.remove("active");
          }

          if (e.target.parentNode.nextElementSibling !== null) {
            e.target.parentNode.nextElementSibling.classList.remove("active");
          }

          e.target.parentNode.classList.add("active");

          for (var p = 0; p < prices.length; p++) {
            if (prices[p].dataset.weight == this.dataset.weight) {
              prices[p].classList.add("active");
            } else {
              prices[p].classList.remove("active");
            }
          }
        });
      }

      if (document.querySelector('.product-gallery-carousel') !== null) {
        //console.log('.products-carousel');
        var currentSlideIndexElement = document.querySelector('.product-gallery-carousel-index .current-slide')
        // require('flickity-imagesloaded');
        var productGalleryCarousel = new Flickity('.product-gallery-carousel', {
            // cellAlign: 'left',
            adaptiveHeight: false,
            wrapAround: true,
            // autoPlay: 5000,
            // groupCells: true,
            pageDots: false,
            prevNextButtons: true,
            // imagesLoaded: true
            // pauseAutoPlayOnHover: false,
            // selectedAttraction: 0.1,
            // friction: 1
            on: {
              // ready: function() {
              //   console.log('Flickity is ready');
              // },
              change: function( index ) {
                // console.log( 'Slide changed to ' + index );
                var currentSlide = index + 1;
                //console.log(currentSlide.length);
                if (currentSlide < 10) {
                  currentSlide = "0" + currentSlide;
                }
                currentSlideIndexElement.textContent = currentSlide;
              }
            }
        });
      }
    },
    finalize() {
    }
};
