var extendDefaults = require("@giantpeach/extend-defaults");
const isBot = !("onscroll" in window) || /glebot/.test(navigator.userAgent);

"use strict";

function Scroll(element, opt) {
    var that = this,
        i = 0;

    this.options = {
        lastScroll: 0,
        ticking: false,
        scrollContainer: window, // window or element,
        scrollDirection: "y", // x or y,
        scroll: {
            width: 0,
            height: 0
        },
        window: {
            width: 0,
            height: 0
        },
        document: {
            width: 0,
            height: 0,
            center: 0
        },
        element: {
            el: "",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            center: 0
        },
        trigger: "center",
        triggerOffset: 0,
        triggerOffsetPercentage: 0,
        callback: ""
    };

    if (typeof opt === "object") {
        extendDefaults(that.options, opt);
    }

    that.options.window.width = window.innerWidth;
    that.options.window.height = window.innerHeight;
    that.options.scroll.width = that.options.scrollContainer.offsetWidth;
    that.options.scroll.height = that.options.scrollContainer.offsetHeight;
    that.options.element.el = element;
    this.calculatePosition();

    this.options.scrolllistener = that.onScroll.bind(that);
    this.clean = this.cleanUp;

    window.addEventListener("scroll", this.options.scrolllistener, false);
}

Scroll.prototype.calculatePosition = function () {
    var rect = this.options.element.el.getBoundingClientRect();
    var body = document.body,
        html = document.documentElement,
        scrollTop,
        scrollLeft;

    scrollTop = this.options.scrollContainer.scrollTop || window.pageYOffset || document.documentElement.scrollTop;
    scrollLeft = this.options.scrollContainer.scrollLeft || window.pageXOffset || document.documentElement.scrollLeft;

    this.options.document.height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
    this.options.document.width = document.documentElement.scrollWidth;

    if (this.options.triggerOffsetPercentage > 0) {
        var height = this.options.element.el.offsetHeight;
        var percentage = this.options.triggerOffsetPercentage * height;
        this.options.triggerOffset = percentage / 100;
    }

    if (this.options.scrollDirection === "y") {
        if (this.options.trigger === "center") {
            // this.options.element.top = rect.top + scrollTop - (( (rect.bottom + scrollTop) - (rect.top + scrollTop)) / 2);
            this.options.element.top = rect.top + scrollTop + this.options.triggerOffset - ( (rect.height) / 2);
            this.options.element.top = rect.top + scrollTop + this.options.triggerOffset;
            this.options.element.bottom = rect.bottom + scrollTop + this.options.triggerOffset - ((rect.bottom - rect.top) / 2);
        } else {
            this.options.element.top = rect.top + scrollTop + this.options.triggerOffset;
            this.options.element.bottom = rect.bottom + scrollTop + this.options.triggerOffset;
        }
    } else if (this.options.scrollDirection === "x") {
        if (this.options.trigger === "center") {
            // this.options.element.top = rect.top + scrollTop - (( (rect.bottom + scrollTop) - (rect.top + scrollTop)) / 2);
            this.options.element.left = rect.left + scrollLeft - ( (rect.width) / 2);
            this.options.element.left = rect.left + scrollLeft;
            this.options.element.right = rect.right + scrollLeft - ((rect.right - rect.left) / 2);
        } else {
            this.options.element.left = rect.left + scrollLeft;
            this.options.element.right = rect.right + scrollLeft;
        }
    }


    if (isBot) {
        this.options.callback.call(this.options.element.el);
    }

    this.requestTick();
};

Scroll.prototype.cleanUp = function () {
    window.removeEventListener("scroll", this.options.scrolllistener, false);
};

Scroll.prototype.onScroll = function () {
    if (this.options.scrollDirection === "y") {
        this.options.lastScroll = this.options.scrollContainer.scrollY || this.options.scrollContainer.scrollTop;
    } else {
        this.options.lastScroll = this.options.scrollContainer.scrollX || this.options.scrollContainer.scrollLeft;
    }

    this.options.document.center = this.options.lastScroll + (this.options.window.height / 2);
    this.requestTick();
};

Scroll.prototype.update = function () {
    this.options.ticking = false;

    if (this.options.scrollContainer === window) {
        if (this.options.scrollDirection === "y") {
            if (this.options.trigger == "center") {
                if (parseInt(this.options.lastScroll + (this.options.window.height / 2)) >= this.options.element.top ) {
                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }

                }
            } else if (this.options.trigger == "top") {
                if (this.options.lastScroll + this.options.window.height >= this.options.element.top ) {
                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }

                }
            } else {
                if (this.options.lastScroll + this.options.window.height > this.options.element.bottom) {

                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }
                }
            }
        } else {
            if (this.options.trigger == "center") {
                if (parseInt(this.options.lastScroll + (this.options.window.width / 2)) >= this.options.element.left ) {
                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }

                }
            } else if (this.options.trigger == "top") {
                if (this.options.lastScroll >= this.options.element.left ) {
                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }

                }
            } else {
                if (this.options.lastScroll + this.options.window.width > this.options.element.right) {

                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }
                }
            }
        }
    } else {
        if (this.options.scrollDirection === "y") {
            if (this.options.trigger == "center") {
                if (parseInt(this.options.lastScroll + (this.options.scroll.height / 2)) >= this.options.element.top ) {
                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }

                }
            } else if (this.options.trigger == "top") {
                if (this.options.lastScroll >= this.options.element.top ) {
                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }

                }
            } else {
                if (this.options.lastScroll + this.options.scroll.height > this.options.element.bottom) {

                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }
                }
            }
        } else {
            if (this.options.trigger == "center") {
                if (parseInt(this.options.lastScroll + (this.options.scroll.width / 2)) >= this.options.element.left ) {
                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }

                }
            } else if (this.options.trigger == "top") {
                if (this.options.lastScroll >= this.options.element.left ) {
                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }

                }
            } else {
                if (this.options.lastScroll + this.options.scroll.width > this.options.element.right) {

                    this.options.callback.call(this.options.element.el);

                    if (this.options.once) {
                        window.removeEventListener("scroll", this.options.scrolllistener, false);
                    }
                }
            }
        }
    }

};

Scroll.prototype.requestTick = function () {
    if (!this.options.ticking) {
        window.requestAnimationFrame(this.update.bind(this));
    }
    this.options.ticking = true;
};

export default Scroll;