// import "jquery";
// import 'bootstrap/js/dist/collapse';
// import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/util';
// import "babel-polyfill";
import Router from './util/Router';

// Routes
import common from "./routes/common";
import home from "./routes/home";
// import blog from "./routes/blog";
import pageTemplateContact from "./routes/contact";
import pageTemplateWholesale from "./routes/wholesale";
import pageTemplateProducts from "./routes/products";
import singleProduct from "./routes/single-product";
import taxProductCategory from "./routes/product-category";
//import productArchive from "./routes/products";

const routes = new Router({
    common,
    home,
    singleProduct,
    pageTemplateProducts,
    pageTemplateContact,
    taxProductCategory
    //blog,
    //pageTemplateFocus,
});

function ready(func) {
    if (
      document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading'
    ) {
      func();
    } else {
      document.addEventListener('DOMContentLoaded', func);
    }
}

ready(function() {
  routes.loadEvents();
});
